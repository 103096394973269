import React from "react"
import "./index.css"

export default function NotFound() {
  // document.title = "Not Found | Rohan"

  return (
    <div id="not-found">
      <div className="container-h noselect">
        <h1 className="giant noselect">404</h1>
        <div className="divider"></div>
        <h3 className="small">Not Found</h3>
      </div>

      <div className="content">
        <p>
          The page you are looking for does not exist. Please recheck the URL
          and try again.
        </p>
        <p>
          If you’re still facing problems, contact{" "}
          <a href="mailto:hey@rohantinna.com">hey@rohantinna.com</a>.
        </p>
        <br />
        <br />
        <p>
          Click here to go to <a href="/">home</a>.
        </p>
      </div>
    </div>
  )
}
